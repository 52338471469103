export default class Metronome {
    constructor(soundFileUrl, bpm) {
        this.soundFileUrl = soundFileUrl;
        this.minBpm = 30;
        this.maxBpm = 300;
        this.audioContext = new AudioContext();
        this.audioContext.suspend();
        this.source = this.audioContext.createBufferSource();
        fetch(soundFileUrl)
            .then((response) => response.arrayBuffer())
            .then((buffer) => this.audioContext.decodeAudioData(buffer))
            .then((buffer) => {
            this.source.buffer = buffer;
            this.source.loop = true;
            this.source.loopEnd = 1 / (bpm / 60);
            this.source.connect(this.audioContext.destination);
            this.source.start(0);
        });
    }
    start() {
        this.audioContext.resume();
    }
    stop() {
        this.audioContext.suspend();
    }
    setTempo(bpm) {
        this.source.loopEnd = 1 / (bpm / 60);
    }
    normalizeBpm(bpm) {
        if (bpm < this.minBpm) {
            return this.minBpm;
        }
        if (bpm > this.maxBpm) {
            return this.maxBpm;
        }
        if (bpm % 1 !== 0) {
            return Math.floor(bpm);
        }
        return bpm;
    }
}
