export default class WakeLockService {
    constructor() {
        this.wakeLockSupported = "wakeLock" in navigator;
        this.lock = null;
        this.requestWakeLock = async () => {
            if (this.wakeLockSupported) {
                this.lock = await navigator.wakeLock.request("screen");
            }
        };
        this.releaseWakeLock = () => {
            if (this.wakeLockSupported && this.lock) {
                this.lock.release();
                this.lock = null;
            }
        };
    }
}
