export default class Tempo {
    constructor(name, goalBpm, order) {
        this.name = name;
        this.goalBpm = goalBpm;
        this.order = order;
        if (this.goalBpm <= 45) {
            this.bpm = 30;
        }
        else {
            this.bpm = Math.floor(goalBpm * 0.75);
        }
    }
}
