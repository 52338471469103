<footer>
    <div>
        <a href="https://github.com/davidtimovski/metronome" target="_blank" rel="noopener">View on GitHub</a> <i class="fab fa-github"></i>
    </div>
</footer>

<style lang="scss">
    footer {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 10%;

        a {
            color: inherit;
        }

        i {
            margin-left: 4px;
            font-size: 20px;
        }
    }
</style>